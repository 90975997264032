/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getTurnovers } from '../fn/turnovers/get-turnovers';
import { GetTurnovers$Params } from '../fn/turnovers/get-turnovers';
import { ResponseBodyTurnoverDto } from '../models/response-body-turnover-dto';

@Injectable({ providedIn: 'root' })
export class TurnoversService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getTurnovers()` */
  static readonly GetTurnoversPath = '/svgs/{svgId}/customers/{customerId}/turnovers';

  /**
   * Get paginated turnovers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTurnovers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTurnovers$Response(params: GetTurnovers$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseBodyTurnoverDto>> {
    return getTurnovers(this.http, this.rootUrl, params, context);
  }

  /**
   * Get paginated turnovers.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTurnovers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTurnovers(params: GetTurnovers$Params, context?: HttpContext): Observable<ResponseBodyTurnoverDto> {
    return this.getTurnovers$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseBodyTurnoverDto>): ResponseBodyTurnoverDto => r.body)
    );
  }

}
