/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ResponseBodyTurnoverDto } from '../../models/response-body-turnover-dto';

export interface GetTurnovers$Params {
  sortBy?: string;
  sortOrder?: string;

/**
 * offset for pagination
 */
  offset?: number;

/**
 * limit for pagination
 */
  limit?: number;

/**
 * SVG Number like 34 or 55
 */
  svgId: string;
  customerId: string;

/**
 * filter with a list of tc payment numbers
 */
  tcPaymentNumbers?: Array<string> | null;
}

export function getTurnovers(http: HttpClient, rootUrl: string, params: GetTurnovers$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseBodyTurnoverDto>> {
  const rb = new RequestBuilder(rootUrl, getTurnovers.PATH, 'get');
  if (params) {
    rb.query('sortBy', params.sortBy, {"explode":false});
    rb.query('sortOrder', params.sortOrder, {"explode":false});
    rb.query('offset', params.offset, {"explode":false});
    rb.query('limit', params.limit, {"explode":false});
    rb.path('svgId', params.svgId, {});
    rb.path('customerId', params.customerId, {});
    rb.query('tcPaymentNumbers', params.tcPaymentNumbers, {"explode":false});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResponseBodyTurnoverDto>;
    })
  );
}

getTurnovers.PATH = '/svgs/{svgId}/customers/{customerId}/turnovers';
